import React, { useState, useEffect } from 'react'
import { Row, Col, Radio, Modal, Button, notification } from 'antd'
import client from '../../api/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { isMobileOnly } from 'react-device-detect'

export default function OrderTypeSelection(props) {
  const [categoryList, setCategoryList] = useState([])
  const [orderItemTypeList, setOrderItemTypeList] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(1)
  const [selectTypeModalVisible, setSelectTypeModalVisible] = useState(false)

  useEffect(() => {
    const init = async () => {
      const res = await client.get('/api/lookup/orderitemtypes')
      setOrderItemTypeList(res.data)
      const resArr = []
      res.data
        .map((x) => ({ id: x.uPostTypeId, name: x.uPostTypeName }))
        .forEach((el) => {
          if (resArr.findIndex((x) => x.id === el.id) < 0) {
            resArr.push({ id: el.id, name: el.name })
          }
        })
      setCategoryList(
        resArr.sort((a, b) => {
          return a.id - b.id
        }),
      )
    }
    init()
  }, [])

  useEffect(() => {
    if (props.defaultSelectedOrderTypes && props.defaultSelectedOrderTypes.length > 0) {
      setSelectedTypes(props.defaultSelectedOrderTypes)
    }
  }, [props.defaultSelectedOrderTypes])

  const addSelectedType = (selectedTypeId) => {
    setSelectedTypes([...selectedTypes, selectedTypeId])
    notification.destroy()
    notification.success({
      message: 'Илгээмжийн төрөл нэмэгдлээ',
    })
    props.refreshOrderItemTypeList([...selectedTypes, selectedTypeId])
  }

  const removeSelectedType = (selectedTypeId) => {
    setSelectedTypes(selectedTypes.filter((x) => x !== selectedTypeId))
    notification.destroy()
    notification.success({
      message: 'Илгээмжийн төрөл хасагдлаа',
    })
    props.refreshOrderItemTypeList(selectedTypes.filter((x) => x !== selectedTypeId))
  }

  return (
    <div>
      <div>
        <div className="order-type-container" style={{ paddingLeft: 5 }}>
          <span style={{ display: 'inline-block', marginRight: 4, color: '#ff4d4f', fontSize: 14, fontFamily: 'SimSun, sans-serif' }}>*</span>
          <span style={props.isKiosk ? { fontSize: 24 } : { fontSize: 18 }}>Явуулж буй илгээмжээ сонгоно уу:</span>
        </div>
        <Row gutter={[20, 15]} style={{ width: '100%' }}>
          {orderItemTypeList
            .filter((x) => selectedTypes.includes(x.id))
            .map((el, idx) => {
              return (
                <Col flex="180px" key={idx}>
                  <div className="selectedShipmentTypes">
                    <Button
                      onClick={() => removeSelectedType(el.id)}
                      style={{ position: 'absolute', top: 0, right: 10, background: 'transparent', width: 30, padding: 0, border: 'none' }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <div>{!isMobileOnly && <img src={el.url} alt="" style={{ maxWidth: 64, width: 64 }} />}</div>
                    <div className="mt-2">{el.name}</div>
                  </div>
                </Col>
              )
            })}
          <Col flex="180px">
            <Button
              className="add-type-btn"
              style={{ width: 156, height: 150, background: '#f5f5f5' }}
              onClick={() => setSelectTypeModalVisible(true)}
            >
              <span style={{ fontSize: 60 }}> + </span>
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        title="Илгээмжийн төрөл нэмэх"
        open={selectTypeModalVisible}
        onCancel={() => setSelectTypeModalVisible(false)}
        className="sb-modal sb-modal-kiosk"
        width={800}
        destroyOnClose
        centered
        maskStyle={{ background: '#11427FB9' }}
        footer={[
          <div className="sb-modal-footer" key="footer">
            <Button className="btn-sb btn-sb-primary px-5" onClick={() => setSelectTypeModalVisible(false)}>
              Гарах
            </Button>
          </div>,
        ]}
      >
        <div>
          <Radio.Group
            className="sb-radio-buttons"
            value={selectedCategory}
            onChange={async (e) => {
              await setSelectedCategory(null)
              await setSelectedCategory(e.target.value)
            }}
          >
            {categoryList.map((el, idx) => {
              return (
                <Radio.Button value={el.id} key={idx}>
                  {el.name.split('/').map((name, index) => (
                    <React.Fragment key={index}>{name}</React.Fragment>
                  ))}
                </Radio.Button>
              )
            })}
          </Radio.Group>
          {selectedCategory && (
            <div className="mt-2 order-type-list-container">
              <div className="sb-checkbox-buttons">
                <Row gutter={[20, 15]} style={{ width: '100%', margin: 0 }}>
                  {orderItemTypeList
                    .filter((el) => el.uPostTypeId === selectedCategory && selectedTypes.indexOf(el.id) < 0)
                    .map((el, idx) => {
                      return (
                        <Col xs={12} sm={6} key={idx}>
                          <Button className="chooseShipmentType" onClick={() => addSelectedType(el.id)}>
                            <div>{!isMobileOnly && <img src={el.url} alt="төрөл" style={{ maxWidth: 64, width: 64 }} />}</div>
                            <div className="mt-2">{el.name}</div>
                          </Button>
                        </Col>
                      )
                    })}
                </Row>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
