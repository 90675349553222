import React, { useState, useEffect } from 'react'
import { Radio, Modal, Row, Col, Button, Input } from 'antd'

function PriceSelection({ parentCallback, isPriceCustom, defaultValue }) {
  const [customPrice, setCustomPrice] = useState('')
  const [isCustom, setIsCustom] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [otherTitle, setOtherTitle] = useState('Бусад')
  const [selectedValue, setSelectedValue] = useState(-1)

  useEffect(() => {
    setIsCustom(isPriceCustom)
    if (isPriceCustom) {
      setOtherTitle('Бусад: $' + defaultValue)
      setSelectedValue(undefined)
      setCustomPrice(defaultValue)
    } else {
      setSelectedValue(defaultValue)
    }
  }, [isPriceCustom, defaultValue])

  const changeCustomPrice = () => {
    setModalVisible(false)
    if (customPrice > 0) {
      setOtherTitle('Бусад: $' + customPrice)
      setSelectedValue(undefined)
      setIsCustom(true)
      parentCallback(customPrice, true)
    } else {
      setOtherTitle('Бусад')
      setIsCustom(false)
    }
  }

  return (
    <div>
      <Radio.Group
        className="sb-radio-buttons"
        value={selectedValue}
        onChange={(e) => {
          setSelectedValue(e.target.value)
          setIsCustom(false)
          setCustomPrice(0)
          setOtherTitle('Бусад')
          parentCallback(e.target.value)
        }}
      >
        <Radio value={20}>$20</Radio>
        <Radio value={50}>$50</Radio>
        <Radio value={80}>$80</Radio>
        <Radio value={100}>$100</Radio>
        <Button className="custom-price-btn" style={isCustom ? { backgroundColor: '#f8d038' } : {}} onClick={() => setModalVisible(true)}>
          {otherTitle}
        </Button>
      </Radio.Group>
      <Modal
        title="Илгээмжийн үнийн дүн"
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false)
          if (!customPrice) {
            setIsCustom(false)
            setOtherTitle('Бусад')
          }
        }}
        className="sb-modal sb-modal-kiosk"
        width={400}
        destroyOnClose
        centered
        footer={
          <Row justify="center" key={'modal'}>
            <Col span={24}>
              <Button className="btn-sb btn-sb-primary w-100" onClick={() => changeCustomPrice()}>
                Хадгалах
              </Button>
            </Col>
          </Row>
        }
      >
        <Input
          // eslint-disable-next-line
          autoFocus
          placeholder="Үнийн дүн"
          className="sb-input sb-shadow"
          style={{ height: 60 }}
          onChange={(e) => {
            const val = e.target.value.replace(/\D/g, '')
            setCustomPrice(val ? val : 0)
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              changeCustomPrice()
            }
          }}
        />
      </Modal>
    </div>
  )
}
export default PriceSelection
