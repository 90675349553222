import { faChevronDown, faChevronRight, faTrash, faUser, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Input, Modal, notification, Radio, Row, Select, Spin } from 'antd'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { isAdmin } from '../../api/auth'
import client from '../../api/client'
import SearchFieldWithKeyUpListener from '../search-field-with-key-up-listener'
import { getLocalStorageItem } from '../../constants/helper-functions'
const initialPagination = {
  current: 1,
  pageSize: 8,
}
const initialSorter = {
  field: 'firstName',
  order: 'descend',
}

const initialPaginatedData = {
  search: '',
  pagination: initialPagination,
  sorter: initialSorter,
  includeContainerShipmentType: true,
}

export default function PartyChooser({
  memberId = getLocalStorageItem('accountId'),
  defaultSenderUser,
  defaultReceiverUser,
  setSenderCallback,
  setReceiverCallback,
}) {
  const [createSenderForm] = Form.useForm()
  const [createReceiverForm] = Form.useForm()
  const [senderSelectedUser, setSenderSelectedUser] = useState(null)
  const [receiverSelectedUser, setReceiverSelectedUser] = useState(null)
  const [allSenderList, setAllSenderList] = useState()
  const [senderList, setSenderList] = useState()
  const [allReceiverList, setAllReceiverList] = useState()
  const [receiverList, setReceiverList] = useState()
  const [addSenderModalVisible, setAddSenderModalVisible] = useState(false)
  const [addReceiverModalVisible, setAddReceiverModalVisible] = useState(false)
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  const [provinceId, setProvinceId] = useState(null)
  const [chooseSenderModalVisible, setChooseSenderModalVisible] = useState(false)
  const [chooseReceiverModalVisible, setChooseReceiverModalVisible] = useState(false)
  const [isCompany, setIsCompany] = useState(false)
  const [loading, setLoading] = useState(false)
  const [paginatedData, updatePaginatedData] = useReducer((curr, update) => ({ ...curr, ...update }), initialPaginatedData)

  const { confirm } = Modal

  const handleScroll = async (e, type) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      const endpoint = isAdmin() ? `/api/accounts/${memberId}/${type}/paginated` : `/api/accounts/${type}/paginated`
      setLoading(true)
      // TODO: FIX THIS
      // if (memberId > 0) {
      await client
        .post(endpoint, {
          ...paginatedData,
          pagination: { current: receiverList.data.length / 8 + 1, pageSize: 8 },
        })
        .then((res) => {
          if (receiverList.data.length === res.data.total) {
            notification.warning({ message: "Can't load more..." })
          } else {
            setReceiverList({ data: [...receiverList.data, ...res.data.data], total: res.data.total })
          }
        })
        .finally(() => setLoading(false))
      // }
    }
  }

  const fetchProvinceList = useCallback(() => {
    client
      .get('/api/lookup/provinces')
      .then(async (res) => {
        setProvinceList(
          res.data.map((province) => ({
            value: province.id,
            label: province.name,
          })),
        )
      })
      .catch((err) => console.error({ err }))
  }, [])

  const fetchDistrictList = useCallback((provinceId) => {
    client
      .get(`/api/lookup/provinces/${provinceId}/districts`)
      .then(async (res) => {
        await setDistrictList(
          res.data.map((province) => ({
            value: province.id,
            label: province.name,
          })),
        )
      })
      .catch((err) => console.error({ err }))
  }, [])

  const fetchSubDistrictList = useCallback(
    (districtId, provinceIdForEdit) => {
      const endpoint = `/api/lookup/provinces/${provinceIdForEdit || provinceId}/districts/${districtId}/subdistricts`
      client
        .get(endpoint)
        .then(async (res) => {
          await setSubDistrictList(
            res.data.map((province) => ({
              value: province.id,
              label: province.name,
            })),
          )
        })
        .catch((err) => console.error({ err }))
    },
    [provinceId],
  )

  const fetchSenderList = useCallback(() => {
    setLoading(true)
    if (isAdmin() && memberId > 0) {
      client
        .post(`/api/accounts/${memberId}/senders/paginated`, paginatedData)
        .then(async (res) => {
          setSenderList(res.data)
          setAllSenderList(res.data)
        })
        .finally(() => setLoading(false))
    } else {
      client
        .post('/api/accounts/senders/paginated', paginatedData)
        .then(async (res) => {
          setSenderList(res.data)
          setAllSenderList(res.data)
        })
        .finally(() => setLoading(false))
    }
  }, [memberId, paginatedData])

  const fetchReceiverList = useCallback(() => {
    setLoading(true)
    if (isAdmin() && memberId > 0) {
      client
        .post(`/api/accounts/${memberId}/receivers/paginated`, paginatedData)
        .then(async (res) => {
          setReceiverList(res.data)
        })
        .finally(() => setLoading(false))
    } else {
      client
        .post('/api/accounts/receivers/paginated', paginatedData)
        .then(async (res) => {
          setReceiverList(res.data)
          setAllReceiverList(res.data)
        })
        .finally(() => setLoading(false))
    }
  }, [memberId, paginatedData])

  useEffect(() => {
    setSenderSelectedUser(defaultSenderUser)
  }, [defaultSenderUser])
  useEffect(() => {
    setReceiverSelectedUser(defaultReceiverUser)
  }, [defaultReceiverUser])
  useEffect(() => {
    fetchProvinceList()
  }, [fetchProvinceList])
  useEffect(() => {
    setDistrictList([])
    setSubDistrictList([])
  }, [provinceId])
  useEffect(() => {
    fetchSenderList()
  }, [fetchSenderList])
  useEffect(() => {
    fetchReceiverList()
  }, [fetchReceiverList])
  const formatUSPhone = (number) => {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)
    const zip = cleaned.substring(0, 3)
    const middle = cleaned.substring(3, 6)
    const last = cleaned.substring(6, 10)
    let ret = ''
    if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
    else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
    else if (cleaned.length > 0) ret = `(${zip}`
    createSenderForm.setFieldsValue({
      phoneNumber: ret,
    })
  }
  const formatMNPhone = (number) => {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)
    const first4 = cleaned.substring(0, 4)
    const last4 = cleaned.substring(4, 8)
    let ret = ''
    if (cleaned.length > 4) ret = `${first4} ${last4}`
    else if (cleaned.length > 0) ret = `${first4}`
    createReceiverForm.setFieldsValue({
      phoneNumber: ret,
    })
  }
  const formatUSPhoneRet = (number) => {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)
    const zip = cleaned.substring(0, 3)
    const middle = cleaned.substring(3, 6)
    const last = cleaned.substring(6, 10)
    let ret = ''
    if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
    else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
    else if (cleaned.length > 0) ret = `(${zip}`
    return ret
  }
  const formatMNPhoneRet = (number) => {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)
    const first4 = cleaned.substring(0, 4)
    const last4 = cleaned.substring(4, 8)
    let ret = ''
    if (cleaned.length > 4) ret = `${first4} ${last4}`
    else if (cleaned.length > 0) ret = `${first4}`
    return ret
  }
  const submitSender = async () => {
    await createSenderForm
      .validateFields()
      .then(async (values) => {
        const data = {
          ...values,
          phoneNumber: values.phoneNumber.replace(/\D/g, '').substring(0, 10),
          lastName: values.isCompany ? '' : values.lastName,
        }
        if (data.email !== null) {
          if (data.email?.length === 0) {
            delete data.email
          }
        }
        const endpoint = isAdmin() ? `/api/accounts/1/senders` : `/api/accounts/senders`
        await client
          .post(endpoint, data)
          .then(async (res) => {
            notification.success({
              message: 'Илгээгч амжилттай нэмлээ',
            })
            createSenderForm.resetFields()
            setIsCompany(false)
            setAddSenderModalVisible(false)
            fetchSenderList()
          })
          .catch((error) => {
            console.error(error.message)
            notification.error({ message: 'Алдаа гарлаа' })
          })
      })
      .catch((info) => {
        console.error('Validate Failed:', info)
      })
  }
  const submitReceiver = async () => {
    await createReceiverForm
      .validateFields()
      .then(async (values) => {
        const data = {
          ...values,
          phoneNumber: values.phoneNumber.replace(/\D/g, '').substring(0, 8),
          lastName: values.isCompany ? '' : values.lastName,
        }
        if (data.email !== null) {
          if (data.email?.length === 0) {
            delete data.email
          }
        }
        const endpoint = isAdmin() ? `/api/accounts/${memberId}/receivers` : `/api/accounts/receivers`
        await client
          .post(endpoint, data)
          .then(async (res) => {
            notification.success({
              message: 'Хүлээн авагч амжилттай нэмлээ',
            })
            setIsCompany(false)
            setAddReceiverModalVisible(false)
            fetchReceiverList()
            createReceiverForm.resetFields()
          })
          .catch((error) => {
            console.error(error.message)
            notification.error({ message: 'Алдаа гарлаа' })
          })
      })
      .catch((info) => {
        console.error('Validate Failed:', info)
      })
  }
  const deleteReceiver = async (id) => {
    client
      .delete(`/api/accounts/receivers/${id}`)
      .then(async (res) => {
        notification.success({
          message: 'Хүлээн авагч амжилттай устгагдлаа',
        })
      })
      .catch((error) => {
        console.error(error.message)
      })
      .finally(() => fetchReceiverList())
  }
  const deleteSender = async (id) => {
    client
      .delete(`/api/accounts/senders/${id}`)
      .then(async (res) => {
        notification.success({
          message: 'Илгээгч амжилттай устгагдлаа',
        })
      })
      .catch((error) => {
        console.error(error.message)
      })
      .finally(() => fetchSenderList())
  }
  return (
    <Spin spinning={loading} size="large">
      <div className="mb-1">
        <div>
          <div className="label-neworder" style={{ fontSize: 18 }}>
            <span
              style={{
                lineHeight: 1,
                color: '#ff4d4f',
                fontSize: 14,
                marginLeft: 5,
                marginRight: 4,
                fontFamily: "'SimSun', sans-serif",
              }}
            >
              *
            </span>
            Илгээгч, хүлээн авагчийн мэдээллийг сонгоно уу:
          </div>
        </div>
        <Row gutter={[24, 24]}>
          <Col className="party-item" flex="500px">
            <div style={!senderSelectedUser ? partyBoxStyle : partyBoxStyleSelected} className="sb-shadow">
              <Row style={{ cursor: 'pointer', flexFlow: 'row' }} onClick={() => setChooseSenderModalVisible(true)}>
                <Col flex="auto" className="party-item-info">
                  {!senderSelectedUser ? (
                    <div style={partyBoxContent}>
                      <div>Илгээгчийн мэдээлэл</div>
                    </div>
                  ) : (
                    <div style={partyBoxContentSelected} className="partybox-selected">
                      <div style={{ fontSize: 14 }}>Илгээгчийн мэдээлэл</div>
                      <Row>
                        <Col flex="auto" xs={{ offset: 0 }} lg={{ offset: 2 }}>
                          <h5 className="mb-1">{senderSelectedUser && senderSelectedUser.name}</h5>
                          <h5 className="mb-0">{senderSelectedUser && formatUSPhoneRet(senderSelectedUser.phone)}</h5>
                          <h5 className="mb-0 party-address">{senderSelectedUser && senderSelectedUser.address}</h5>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
                <Col flex="80px" className="party-chooser-arrow">
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronRight} size="lg" style={{ color: '#333', top: '49%' }} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="party-item" flex="500px">
            <div style={!receiverSelectedUser ? partyBoxStyle : partyBoxStyleSelected} className="sb-shadow">
              <Row style={{ cursor: 'pointer', flexFlow: 'row' }} onClick={() => setChooseReceiverModalVisible(true)}>
                <Col flex="auto" className="party-item-info">
                  {!receiverSelectedUser ? (
                    <div style={partyBoxContent}>
                      <div>Хүлээн авагчийн мэдээлэл</div>
                    </div>
                  ) : (
                    <div style={partyBoxContentSelected} className="partybox-selected">
                      <div style={{ fontSize: 14 }}>Хүлээн авагчийн мэдээлэл</div>
                      <Row>
                        <Col flex="auto" xs={{ offset: 0 }} lg={{ offset: 2 }}>
                          <h5 className="mb-1">{receiverSelectedUser ? receiverSelectedUser.name : ''}</h5>
                          <h5 className="mb-0">{receiverSelectedUser ? formatMNPhoneRet(receiverSelectedUser.phone) : ''}</h5>
                          <h5 className="mb-0 party-address">{receiverSelectedUser ? receiverSelectedUser.address : ''}</h5>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
                <Col flex="80px" className="party-chooser-arrow">
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronRight} size="lg" style={{ color: '#333', top: '49%' }} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Modal
          title="Илгээгч сонгох"
          open={chooseSenderModalVisible}
          onCancel={() => {
            setChooseSenderModalVisible(false)
            updatePaginatedData(initialPaginatedData)
          }}
          className="sb-modal sb-modal-kiosk choose-party-modal"
          width={720}
          destroyOnClose
          centered
          footer={false}
        >
          <Row className="mb-3">
            <Col span={12}>
              <div style={{ marginLeft: 12 }}>
                <SearchFieldWithKeyUpListener callback={(search) => updatePaginatedData({ search })} />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24} onScroll={(e) => senderList.data.length > 7 && senderList.data?.length !== senderList.total && handleScroll(e, 'senders')}>
              {senderList?.total > 0 ? (
                <Radio.Group
                  className="sb-radio-buttons sb-party-chooser"
                  value={senderSelectedUser ? senderSelectedUser.id : null}
                  onChange={(e) => {
                    setChooseSenderModalVisible(false)
                    setSenderCallback(e.target.value)
                    setSenderSelectedUser({
                      id: e.target.value,
                      name: e.target.fullName,
                      phone: e.target.phone,
                      address: e.target.address,
                    })
                    setSenderList(allSenderList)
                  }}
                >
                  {senderList.data.map((el) => {
                    if (el.lastName === null) {
                      el.lastName = ''
                    }
                    return (
                      <Radio
                        key={el.id}
                        value={el.id}
                        style={partyChooseStyle}
                        fullName={el.firstName + ' ' + el.lastName}
                        phone={el.phoneNumber}
                        address={el.address}
                      >
                        <Row justify="end" style={{ marginRight: 0 }}>
                          <Button
                            onClick={() => {
                              confirm({
                                title: 'Та илгээгчийг устгахдаа итгэлтэй байна уу?',
                                maskClosable: true,
                                //icon: <faExclamationCircleOutlined />,
                                cancelText: 'Үгүй',
                                okText: 'Тийм',
                                okType: 'danger',
                                className: 'confirm-hs-modal',
                                centered: true,
                                confirmLoading: true,
                                onOk() {
                                  deleteSender(el.id)
                                },
                              })
                            }}
                            style={trashStyle}
                          >
                            <FontAwesomeIcon icon={faTrash} size="sm" style={{ color: '#E3122D' }} />
                          </Button>
                          <Button style={trashStyleAlt}>
                            {el.isCompany ? (
                              <FontAwesomeIcon icon={faBuilding} size="sm" style={{ color: '#000' }} />
                            ) : (
                              <FontAwesomeIcon icon={faUser} size="sm" style={{ color: '#000' }} />
                            )}
                          </Button>
                        </Row>

                        <div className="text-start">
                          {el.firstName} {el.lastName}
                        </div>
                        <div className="text-start">{formatUSPhoneRet(el.phoneNumber)}</div>
                        <div className="text-start">{el.address.length > 30 ? el.address.substring(0, 30) + '...' : el.address}</div>
                      </Radio>
                    )
                  })}
                </Radio.Group>
              ) : (
                <div className="mt-5 mb-3" style={{ textAlign: 'center' }}>
                  Илгээгч байхгүй байна
                </div>
              )}
              <Button className="btn-sb btn-sb-primary px-3" style={{ marginTop: 12, marginLeft: 12 }} onClick={() => setAddSenderModalVisible(true)}>
                <div>Илгээгч шинээр нэмэх</div>
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title="Хүлээн авагч сонгох"
          open={chooseReceiverModalVisible}
          onCancel={() => {
            setChooseReceiverModalVisible(false)
            updatePaginatedData(initialPaginatedData)
          }}
          destroyOnClose
          className="sb-modal sb-modal-kiosk"
          width={720}
          centered
          footer={false}
        >
          <Row className="mb-3">
            <Col span={12}>
              <div style={{ marginLeft: 12 }}>
                <SearchFieldWithKeyUpListener callback={(search) => updatePaginatedData({ search })} />
              </div>
            </Col>
          </Row>
          <Spin spinning={loading}>
            <Row className="mb-3">
              <Col
                span={24}
                onScroll={(e) => receiverList.data.length > 7 && receiverList.data?.length !== receiverList.total && handleScroll(e, 'receivers')}
              >
                {receiverList?.total > 0 ? (
                  <Radio.Group
                    className="sb-radio-buttons sb-party-chooser"
                    value={receiverSelectedUser ? receiverSelectedUser.id : null}
                    onChange={(e) => {
                      setChooseReceiverModalVisible(false)
                      setReceiverCallback(e.target.value)
                      setReceiverSelectedUser({
                        id: e.target.value,
                        name: e.target.fullName,
                        phone: e.target.phone,
                        address: e.target.address,
                      })
                      setReceiverList(allReceiverList)
                    }}
                  >
                    {receiverList.data.map((el) => {
                      return (
                        <Radio
                          key={el.id}
                          value={el.id}
                          style={partyChooseStyle}
                          fullName={el.firstName + ' ' + el.lastName}
                          phone={el.phoneNumber}
                          address={el.fullAddress}
                        >
                          <Row justify="end">
                            <Button
                              onClick={() => {
                                confirm({
                                  title: 'Та хүлээн авагчийг устгахдаа итгэлтэй байна уу?',
                                  maskClosable: true,
                                  //icon: <faExclamationCircleOutlined />,
                                  cancelText: 'Үгүй',
                                  okText: 'Тийм',
                                  okType: 'danger',
                                  className: 'confirm-hs-modal',
                                  centered: true,
                                  confirmLoading: true,
                                  onOk() {
                                    deleteReceiver(el.id)
                                  },
                                })
                              }}
                              style={trashStyle}
                            >
                              <FontAwesomeIcon icon={faTrash} size="sm" style={{ color: '#E3122D' }} />
                            </Button>
                            <Button style={trashStyleAlt}>
                              {el.isCompany ? (
                                <FontAwesomeIcon icon={faBuilding} size="sm" style={{ color: '#000' }} />
                              ) : (
                                <FontAwesomeIcon icon={faUser} size="sm" style={{ color: '#000' }} />
                              )}
                            </Button>
                          </Row>
                          <div className="text-start">
                            {el.firstName} {el.lastName}
                          </div>
                          <div className="text-start">{formatMNPhoneRet(el.phoneNumber)}</div>
                          <div className="text-start">
                            {el.fullAddress && el.fullAddress.length > 30 ? el.fullAddress.substring(0, 30) + '...' : el.fullAddress}
                          </div>
                        </Radio>
                      )
                    })}
                  </Radio.Group>
                ) : (
                  <div className="mt-5 mb-3" style={{ textAlign: 'center' }}>
                    Хүлээн авагч байхгүй байна
                  </div>
                )}
                <Button
                  className="btn-sb btn-sb-primary px-3"
                  style={{ marginTop: 12, marginLeft: 12 }}
                  onClick={() => {
                    setAddReceiverModalVisible(true)
                  }}
                >
                  <div>Хүлээн авагч шинээр нэмэх</div>
                </Button>
              </Col>
            </Row>
          </Spin>
        </Modal>
        <Modal
          title="Илгээгч нэмэх"
          open={addSenderModalVisible}
          onCancel={() => setAddSenderModalVisible(false)}
          className="sb-modal sb-modal-kiosk"
          width={550}
          destroyOnClose
          centered
          maskStyle={{
            background: '#11427FB9',
          }}
          footer={[
            <Row justify="center" key={'modal'}>
              <Col span={12}>
                <Button htmlType="submit" className="btn-sb btn-sb-primary w-100" onClick={() => submitSender()}>
                  Хадгалах
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form form={createSenderForm}>
            <Row gutter={[24, 12]}>
              <Col span={24}>
                <Form.Item name="isCompany">
                  <Select
                    placeholder="Илгээгчийн төрөл"
                    className="sb-select sb-shadow"
                    defaultValue={false}
                    onChange={(val) => {
                      setIsCompany(val)
                    }}
                    options={[
                      { value: false, label: 'Хувь хүн' },
                      { value: true, label: 'Байгууллага' },
                    ]}
                  />
                </Form.Item>
              </Col>
              {isCompany ? (
                <Col span={24}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: isCompany ? true : false,
                        message: 'Байгууллагийн нэрээ оруулна уу!',
                      },
                    ]}
                  >
                    <Input placeholder="Байгууллагийн нэр*" className="sb-input sb-shadow" />
                  </Form.Item>
                </Col>
              ) : (
                <>
                  {' '}
                  <Col span={12}>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: !isCompany ? true : false,
                          message: 'Нэрээ оруулна уу!',
                        },
                      ]}
                    >
                      <Input placeholder="Нэр*" className="sb-input sb-shadow" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: !isCompany ? true : false,
                          message: 'Овгоо оруулна уу!',
                        },
                      ]}
                    >
                      <Input placeholder="Овог*" className="sb-input sb-shadow" />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Утасны дугаараа оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Утас*" className="sb-input sb-shadow" onChange={(e) => formatUSPhone(e.target.value)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email">
                  <Input placeholder="И-мэйл" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Хаягаа оруулна уу!',
                    },
                  ]}
                >
                  <Autocomplete
                    className="ant-input sb-shadow google-map-input"
                    apiKey={'AIzaSyC8meMe-1DKxh70DZotYw6z-CVj4dSpnbc'}
                    onPlaceSelected={async (place) => {
                      createSenderForm.setFieldsValue({
                        address: place.formatted_address,
                      })
                    }}
                    options={{
                      types: ['address'],
                      componentRestrictions: { country: 'US' },
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title="Хүлээн авагч нэмэх"
          open={addReceiverModalVisible}
          onCancel={() => setAddReceiverModalVisible(false)}
          className="sb-modal sb-modal-kiosk"
          width={550}
          destroyOnClose
          centered
          maskStyle={{ background: '#11427FB9' }}
          footer={[
            <Row justify="center" key={'modal'}>
              <Col span={12}>
                <Button htmlType="submit" className="btn-sb btn-sb-primary w-100" onClick={() => submitReceiver()}>
                  Хадгалах
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form form={createReceiverForm}>
            <Row gutter={[24, 12]}>
              <Col span={24}>
                <Form.Item name="isCompany">
                  <Select
                    placeholder="Илгээгчийн төрөл"
                    className="sb-select sb-shadow"
                    defaultValue={false}
                    onChange={(val) => {
                      setIsCompany(val)
                    }}
                    options={[
                      { value: false, label: 'Хувь хүн' },
                      { value: true, label: 'Байгууллага' },
                    ]}
                  />
                </Form.Item>
              </Col>
              {isCompany ? (
                <Col span={24}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: isCompany ? true : false,
                        message: 'Байгууллагийн нэрээ оруулна уу!',
                      },
                    ]}
                  >
                    <Input placeholder="Байгууллагийн нэр*" className="sb-input sb-shadow" />
                  </Form.Item>
                </Col>
              ) : (
                <>
                  {' '}
                  <Col span={12}>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: !isCompany ? true : false,
                          message: 'Нэрээ оруулна уу!',
                        },
                      ]}
                    >
                      <Input placeholder="Нэр*" className="sb-input sb-shadow" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: !isCompany ? true : false,
                          message: 'Овгоо оруулна уу!',
                        },
                      ]}
                    >
                      <Input placeholder="Овог*" className="sb-input sb-shadow" />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Утасны дугаараа оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Утас*" className="sb-input sb-shadow" onChange={(e) => formatMNPhone(e.target.value)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email">
                  <Input placeholder="И-мэйл" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="provinceId"
                  rules={[
                    {
                      required: true,
                      message: 'Хот/Аймаг оруулна уу!',
                    },
                  ]}
                >
                  <Select
                    className="sb-select sb-shadow"
                    popupClassName="sb-select-dropdown"
                    placeholder="Хот/Аймаг"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    options={provinceList}
                    onChange={async (id) => {
                      setProvinceId(id)
                      await fetchDistrictList(id)
                      createReceiverForm.setFieldsValue({
                        districtId: null,
                        subdistrictId: null,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="districtId"
                  rules={[
                    {
                      required: true,
                      message: 'Дүүрэг/Сум оруулна уу!',
                    },
                  ]}
                >
                  <Select
                    className="sb-select sb-shadow"
                    popupClassName="sb-select-dropdown"
                    placeholder="Дүүрэг/Сум"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    options={districtList}
                    onChange={async (id) => {
                      await fetchSubDistrictList(id)
                      createReceiverForm.setFieldsValue({
                        subdistrictId: null,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="subdistrictId"
                  rules={[
                    {
                      required: true,
                      message: 'Хороо/Баг оруулна уу!',
                    },
                  ]}
                >
                  <Select
                    className="sb-select sb-shadow"
                    popupClassName="sb-select-dropdown"
                    placeholder="Хороо/Баг"
                    // showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    options={subDistrictList}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="address">
                  <Input placeholder="Байр" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </Spin>
  )
}

const partyChooseStyle = {
  display: 'flex',
  width: '45%',
  margin: '2.5%',
  position: 'relative',
}
const partyBoxStyle = {
  backgroundColor: '#cbcbcb',
  color: '#333',
  borderRadius: 10,
  padding: 0,
}
const partyBoxStyleSelected = {
  backgroundColor: '#f8d038',
  borderRadius: 10,
  padding: 0,
}
const partyBoxContent = {
  display: 'flex',
  flexDirection: 'column',
  height: '104px',
  paddingLeft: '80px',
  textAlign: 'center',
  justifyContent: 'center',
}
const partyBoxContentSelected = {
  display: 'flex',
  flexDirection: 'column',
  height: '108px',
  padding: '3px 10px',
}
const trashStyle = {
  background: 'transparent',
  border: '1px solid white',
  borderBottomLeftRadius: '10px',
  zIndex: '1',
  backgroundColor: '#cbcbcb',
  borderRadius: '10px',
  borderRight: '1px solid white',
  position: 'absolute',
  right: '3%',
}
const trashStyleAlt = {
  background: 'transparent',
  border: '1px solid white',
  borderBottomLeftRadius: '10px',
  zIndex: '1',
  backgroundColor: '#cbcbcb',
  borderRadius: '10px',
  borderRight: '1px solid white',
  position: 'absolute',
  right: '3%',
  top: '48px',
  cursor: 'default',
}
